import { Link } from "gatsby";
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Highlight
} from '@chakra-ui/react';
import { useContext } from "react";
import { InboundSalesContext } from "../InboundSales";
export { Head } from "../seo";

const IndexPage = () => {
  const { onOpen } = useContext(InboundSalesContext);
  return (
    <>
      <Box bg="gray.50">
        <Container maxW={'3xl'} >
          <Stack
            as={Box}
            textAlign={'left'}
            spacing={{ base: 8, md: 14 }}
            py={12}
          >
            <Heading
              fontWeight={800}
              fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            >
              <Highlight
                query='parking facility!'
                styles={{ color: "purple.500" }}
              >
                Beautiful, mobile-optimized websites for your parking facility!
              </Highlight>
            </Heading>
            <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
              Parkify highlights entry and exit locations, access hours, nearby points-of-interest,
              and parking rates to convert more drivers into paying parkers.
            </Text>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={3}
            >
              <Button
                rounded={'lg'}
                onClick={onOpen}
                colorScheme="brand"
                textColor="black"
              >
                Get Started Today!
              </Button>
              <Button
                as={Link}
                to={`/features`}
                variant={'outline'}
                rounded="lg"
              >
                Learn More
              </Button>
            </Stack>
          </Stack>
        </Container >
      </Box>
      <Box bgGradient='linear(to-t, purple.200, purple.500)'>
        <Container maxW={'3xl'}>
          <Stack
            as={Box}
            textAlign={'left'}
            spacing={{ base: 8, md: 14 }}
            py={12}
          >
            <Heading
              fontWeight={700}
              color={"gray.50"}
              fontSize={{ base: '2xl', sm: '3xl', md: '6xl' }}
            >
              <Highlight
                query='parking revenue!'
                styles={{ color: "gold.500" }}
              >
                Parkify makes your parking facility looks great on Google Maps.
              </Highlight>
            </Heading>
            <Text color={'gray.50'} fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
              Parkify manages your Google Maps Pin to ensure that drivers have the confidence to park at your facility.
            </Text>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={3}
              float={"right"}
            >
              <Button
                as="a"
                colorScheme="brand"
                textColor="black"
                rounded={'lg'}
                onClick={onOpen}
              >
                Get Started Today!
              </Button>
              <Button
                as={Link}
                to={`/features`}
                variant={'outline'}
                rounded="lg"
                _hover={{ fontColor: "black" }}
              >
                <Text color={'gray.50'}
                >
                  Learn More
                </Text>
              </Button>
            </Stack>
          </Stack>
        </Container >
      </Box>
    </>
  );
}

export default IndexPage;